<template>
  <div class="exam container">
    <YForm v-model="tableForm" inline class="y-form" size="medium">
      <headerBar />
      <YField
        inline
        size="medium"
        prefix-icon="el-icon-search"
        name="title"
        placeholder="请输入考试名称"
      ></YField>
      <YField
        size="medium"
        name="state"
        component="el-select"
        placeholder="考试状态"
        :data-source="EXAM_STATUS"
      />
      <YField
        size="medium"
        name="status"
        component="el-select"
        placeholder="审核状态"
        :data-source="EXAM_CHECK_STATUS"
      ></YField>
      <YButton do="search" class="btn btn_blue" />
      <YButton class="btn btn_blue" @click="addExam">
        <i class="el-icon-plus el-icon--left" />
        新建考试
      </YButton>

      <div class="exam-operate">
        <el-link type="info" underline @click="deleteExam()" class="mr8">
          <i class="el-icon-delete"></i>
          删除考试
        </el-link>
        <el-link type="info" underline @click="closeExam()">
          <i class="el-icon-switch-button"></i>
          关闭考试
        </el-link>
      </div>

      <YQueryTable
        ref="queryTable"
        class="table"
        tooltip-effect="dark"
        :serve="getList"
        @selection-change="handleSelection"
      >
        <template>
          <el-table-column type="selection" />
          <el-table-column
            label="考试名称"
            prop="title"
            show-overflow-tooltip
          />

          <el-table-column label="考试类型" prop="typeName" />
          <el-table-column label="考试次数" prop="times" min-width="120" />

          <el-table-column label="考试时间" width="220px" align="center">
            <template slot-scope="scope">
              <span v-if="scope.row.timeLimit">
                {{ scope.row.startTime }} ~
                {{ scope.row.endTime }}
              </span>
              <span v-else>不限时</span>
            </template>
          </el-table-column>

          <el-table-column label="考试时长" align="center">
            <template slot-scope="scope"
              >{{ scope.row.totalTime }}分钟</template
            >
          </el-table-column>
          <el-table-column label="考试总分" prop="totalScore" align="center" />
          <el-table-column label="及格线" prop="qualifyScore" align="center" />
          <el-table-column label="考试状态" prop="name" min-width="120">
            <template slot-scope="scope">{{
              EXAM_STATUS.get(scope.row.state)
            }}</template>
          </el-table-column>
          <el-table-column label="审核状态" prop="name" min-width="120">
            <template slot-scope="scope">
              <!-- 不通过 -->
              <el-tooltip
                manual:true
                v-if="scope.row.status === 3 && scope.row.reason"
                class="item"
                effect="dark"
                :content="scope.row.reason"
              >
                <el-link>
                  {{ EXAM_CHECK_STATUS.get(scope.row.status) }}
                </el-link>
              </el-tooltip>
              <div v-else>
                {{ EXAM_CHECK_STATUS.get(scope.row.status) }}
              </div>
            </template>
          </el-table-column>
          <el-table-column label="操作" prop="name" min-width="280">
            <template slot-scope="scope">
              <el-link
                class="mr8"
                @click="editExam(scope.row)"
                :disabled="scope.row.state === 2 && scope.row.status === 2"
              >
                编辑
              </el-link>
              <el-link
                class="mr8"
                :disabled="scope.row.status !== 1"
                @click="checkExam(scope.row)"
                >审核</el-link
              >
              <el-link
                class="mr8"
                :disabled="
                  [1, 3].includes(scope.row.status) || scope.row.state !== 2
                "
                @click="closeExam([scope.row.id])"
              >
                关闭考试
              </el-link>
              <el-link class="mr8" @click="examStatistic(scope.row)"
                >成绩统计</el-link
              >
              <el-link
                :disabled="[2].includes(scope.row.state)"
                @click="deleteExam([scope.row.id])"
                type="danger"
              >
                删除
              </el-link>
            </template>
          </el-table-column>
        </template>
      </YQueryTable>
    </YForm>

    <el-dialog
      class="dialog_wrapper"
      :title="isEdit ? '编辑考试' : '新建考试'"
      :visible.sync="dialogShow"
    >
      <YForm
        v-loading="formLoading"
        ref="addForm"
        v-model="formValues"
        @submit="saveForm"
        label-position="top"
      >
        <div class="tab">
          <div
            v-for="(item, i) in tabList"
            :key="i"
            @click="currentIndex = i"
            :class="{ current: currentIndex === i }"
          >
            {{ item }}
          </div>
        </div>
        <div v-show="currentIndex === 0">
          <YField
            label="考试名称"
            name="title"
            :maxlength="15"
            show-word-limit
            :rules="['required', 'whiteSpace']"
          />
          <YField
            :label="label.medicalLabel"
            name="typeId"
            component="el-cascader"
            :options="medicalTree"
            :rules="['required', { trigger: 'change' }]"
            :props="{
              label: 'medicalName',
              checkStrictly: true,
              emitPath: false,
              value: 'medicalId',
            }"
          />
          <YField
            label="考试须知"
            type="textarea"
            name="content"
            show-word-limit
            :maxlength="500"
            :rules="['required', 'whiteSpace']"
          />
          <SelectMore
            rowKey="repoId"
            v-model="selectedQuestionStore"
            multiple
            label="选择题库"
            :rules="['required']"
            title="请选择题库"
            row-key="id"
            :columns="columns"
            :serve="getQuestionStroe"
          >
            <div class="el-form-item is-success yfield">
              <label
                class="yfield__label size-small"
                style="display: inline-block; text-align: right"
                >选择题库</label
              >
              <div class="yfield__content size-small" style="margin-left: 80px">
                <el-button class="btn btn_blue">请选择</el-button>
              </div>
            </div>
            <template slot="searchForm">
              <YField name="title" placeholder="请输入题库名称" />
              <YButton class="btn btn_blue" do="search" />
            </template>
          </SelectMore>
          <div v-if="selectedQuestionStore.length">
            <p style="font-size: 14px; margin-bottom: 14px">已选题库</p>
            <ul>
              <li v-for="item in selectedQuestionStore" :key="item.id">
                {{ item.title }}
              </li>
            </ul>
          </div>
        </div>
        <div v-show="currentIndex === 1">
          <YField
            label="设定题数"
            :component="SetQuestion"
            :list="selectedQuestionStore"
            name="repoList"
          />
        </div>
        <div v-show="currentIndex === 2">
          <YField
            label="次数限制"
            name="isInfinity"
            component="el-radio-group"
            :rules="['required', { trigger: 'change' }]"
          >
            <el-radio :label="true" border>无限次</el-radio>
            <el-radio :label="false" border>有限次</el-radio>
          </YField>
          <YField
            label="考试次数"
            v-if="formValues.isInfinity === false"
            name="times"
            :maxlength="10"
            :rules="['required', 'digital', minValidate]"
          ></YField>
          <YField
            label="请输入考试时长（单位：分钟）"
            compoennt="el-input"
            name="totalTime"
            :maxlength="3"
            :rules="['required', 'digital', minValidate]"
          >
            分钟
          </YField>
          <YField
            :label="`及格分数(总分${totalCount})`"
            name="qualifyScore"
            :rules="['required', 'digital', maxScore]"
          ></YField>
          <YField
            label="考试限时"
            name="timeLimit"
            component="el-radio-group"
            :rules="['required', { trigger: 'change' }]"
          >
            <el-radio :label="false" border>关闭限时</el-radio>
            <el-radio :label="true" border>开启限时</el-radio>
          </YField>
          <YField
            v-if="formValues.timeLimit"
            label="考试时间"
            name="date"
            component="el-date-picker"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            value-format="yyyy-MM-dd"
            end-placeholder="结束日期"
            :rules="['required']"
            :picker-options="startTimeOption"
          />
        </div>
        <div class="dialog-footer">
          <YButton
            v-if="currentIndex !== 0"
            @click="currentIndex--"
            class="btn btn_cancle btn_normal"
            >上一步</YButton
          >
          <YButton
            type="default"
            v-else
            @click="dialogShow = false"
            class="btn btn_cancle btn_normal"
            >取消</YButton
          >
          <YButton
            v-if="currentIndex === tabList.length - 1"
            class="btn btn_blue"
            do="submit"
          />
          <YButton v-else @click="currentIndex++" class="btn btn_blue"
            >下一步</YButton
          >
        </div>
      </YForm>
    </el-dialog>

    <el-dialog title="成绩统计" :visible.sync="statisticDialog">
      <div class="statistic-content" v-loading="formLoading">
        <div class="statistic-name">考试名称：{{ examDetail.title }}</div>
        <ul class="statistic-detail">
          <li>
            <span class="key">总分：</span>
            <span class="value">{{ examDetail.totalScore }}分</span>
          </li>
          <li>
            <span class="key">及格分：</span>
            <span class="value">{{ examDetail.qualifyScore }}分</span>
          </li>
          <li>
            <span class="key">题数：</span>
            <span class="value">{{ examDetail.quNum }}题</span>
          </li>
        </ul>
        <div class="statistic-chart flex-between">
          <div>
            <el-progress
              type="circle"
              :percentage="passedRate"
              color="#F65160"
            ></el-progress>
          </div>
          <ul>
            <li>
              <span class="key">参考人数</span>
              <span class="value">{{ examDetail.allPeople }}人</span>
            </li>
            <li>
              <span class="key">及格人数</span>
              <span class="value">{{ examDetail.passedPeople }}人</span>
            </li>
            <li>
              <span class="key">及格率</span>
              <span class="value">{{ passedRate }}%</span>
            </li>
          </ul>
        </div>
      </div>
    </el-dialog>

    <el-dialog
      title="审核"
      :visible.sync="checkDialog"
      v-if="checkDialog"
      class="dialog_wrapper"
      :close-on-click-modal="false"
    >
      <YForm
        v-model="checkForm"
        ref="checkForm"
        label-position="top"
        @submit="saveCheck"
      >
        <YField
          label="审核结果"
          :rules="['required', { trigger: 'change' }]"
          component="el-radio-group"
          name="status"
        >
          <el-radio :label="2">通过</el-radio>
          <el-radio :label="3">拒绝</el-radio>
        </YField>
        <YField
          v-if="checkForm.status === 3"
          :maxlength="500"
          label="拒绝原因"
          show-word-limit
          type="textarea"
          name="reason"
        />
        <div class="dialog-footer">
          <YButton class="btn btn_blue" do="submit" />
          <YButton
            class="btn btn_cancle btn_normal"
            type="default"
            @click="checkDialog = false"
            >取消</YButton
          >
        </div>
      </YForm>
    </el-dialog>
  </div>
</template>

<script>
import { EXAM_STATUS, EXAM_CHECK_STATUS } from "@/utils/enum.js";
import { deleteRequest, tree2arr, formateTreeChildren } from "@/utils/index.js";
import {
  fetchExamList,
  fetchQuestionStoreList,
  saveExam,
  fetchExamDetail,
  deleteExam,
  setExamState,
  fetchExamStatistic,
  checkExam,
  fetchMeaicalList,
} from "@/apis/backStageEndAPI/examStageManagement/index.js";
import SelectMore from "./components/selectMore/dialog";
import SetQuestion from "./components/setQuestion.vue";
import cloneDeep from "lodash/cloneDeep";
import headerBar from "@/components/backStageComponent/layout/headerBar/headerBar";

export default {
  data() {
    return {
      checkDialog: false,
      medicalTree: null,
      dataList: [],
      medicalList: null,
      checkForm: {},
      SetQuestion,
      formLoading: true,
      statisticDialog: false,
      examDetail: {},
      tableForm: {},
      formValues: {},
      EXAM_STATUS,
      EXAM_CHECK_STATUS,
      selectedRows: [],
      isEdit: false,
      selectedQuestionStore: [],
      dialogShow: false,
      currentIndex: 0,
      tabList: ["试卷设置", "题型设置", "考试设置"],
      columns: [
        { label: "题库名称", prop: "title" },
        { label: "题库类别", prop: "typeName" },
        { label: "题目数量", prop: "quNumber" },
      ],
      minValidate: {
        validator(rules, value, callback) {
          if (value < 1) {
            return callback(new Error("最小值不得小于1"));
          }
          callback();
        },
      },
      startTimeOption: {
        disabledDate(date) {
          return date.getTime() < Date.now() - 8.64e7;
        },
      },
      label: {},
    };
  },
  components: { headerBar, SelectMore },
  computed: {
    passedRate({ examDetail }) {
      const { allPeople, passedPeople } = examDetail;
      const rate = (passedPeople * 100) / allPeople;
      return rate ? rate.toFixed(2) : 0;
    },
    totalCount({ formValues }) {
      const { repoList = [] } = formValues;
      return (repoList || []).reduce((sum, item) => {
        const {
          judgeCount = 0,
          inputCount = 0,
          multiCount = 0,
          radioCount = 0,
          judgeScore = 0,
          inputScore = 0,
          multiScore = 0,
          radioScore = 0,
        } = item;
        sum +=
          judgeCount * judgeScore +
          inputCount * inputScore +
          multiCount * multiScore +
          radioCount * radioScore;
        return sum;
      }, 0);
    },
    maxScore({ totalCount }) {
      return {
        validator(rules, value, callback) {
          if (value > totalCount) {
            return callback(new Error("及格分高于总分"));
          }
          callback();
        },
      };
    },
  },
  mounted() {
    this.label = this.$store.state.label;
  },
  methods: {
    checkExam(row) {
      const ids = [row.id] || this.selectedRows.map((e) => e.id);
      if (!ids || !ids.length) {
        return this.$message.warning("最少选择一条数据");
      }
      this.checkForm = {
        ids,
      };
      this.checkForm.reason = "内容不合格";
      this.checkDialog = true;
      this.clearValidate(this.$refs.checkForm);
    },

    async getMedicalList() {
      const list = await fetchMeaicalList();
      this.medicalTree = formateTreeChildren(list);
      this.medicalList = tree2arr(list);
    },

    async saveCheck() {
      await checkExam(this.checkForm);
      this.checkDialog = false;
      this.$refs.queryTable.runServe();
    },

    clearValidate(dom) {
      dom = dom || this.$refs.addForm;
      dom && this.$nextTick(dom.clearValidate);
    },

    addExam() {
      this.formValues = {};
      this.selectedQuestionStore = [];
      this.isEdit = false;
      this.currentIndex = 0;
      this.dialogShow = true;
      if (!this.medicalList) {
        this.getMedicalList();
      }
      this.formLoading = false;
      this.clearValidate();
    },

    async deleteExam(ids) {
      ids = ids || this.selectedRows.map((e) => e.id);
      const hasExaming = ids.some((id) => {
        return this.dataList.find((item) => item.id === id).state === 2;
      });
      if (hasExaming) {
        return this.$message.warning("考试中的试卷不能删除");
      }
      await deleteRequest(deleteExam, ids);
      this.$refs.queryTable.runServe();
    },

    async examStatistic(row) {
      this.formLoading = true;
      const { data } = await fetchExamStatistic(row.id);
      this.formLoading = false;
      this.examDetail = {
        ...row,
        ...data,
      };
      this.statisticDialog = true;
    },

    async getQuestionStroe({ formValues, params }) {
      const { data } = await fetchQuestionStoreList({
        current: params.currentPage,
        size: params.pageSize,
        params: formValues,
      });

      const list = (data?.records || []).map((item) => {
        return {
          totalRadio: item.radioCount,
          totalMulti: item.multiCount,
          totalJudge: item.judgeCount,
          totalInput: item.inputCount,
          repoId: item.id,
          typeName: item.typeName,
          quNumber: item.quNumber,
          title: item.title,
        };
      });
      return {
        data: list,
        total: data?.total,
      };
    },

    async editExam(row) {
      this.formLoading = true;
      this.currentIndex = 0;
      this.isEdit = true;
      const { data = {} } = await fetchExamDetail({
        id: row.id,
      });
      const { endTime = "", startTime = "" } = data;
      this.formLoading = false;
      this.formValues.date = [startTime, endTime];
      const { repoList = [], times } = data;

      this.formValues = {
        ...data,
        date: [startTime, endTime],
        isInfinity: !times,
      };
      this.selectedQuestionStore = cloneDeep(repoList);
      this.dialogShow = true;
      if (!this.medicalList) {
        this.getMedicalList();
      }
      this.clearValidate();
    },

    async saveForm() {
      try {
        const { date = [], typeId } = this.formValues;
        const [startTime = "", endTime = ""] = date;
        delete this.formValues.updateTime;
        delete this.formValues.createTime;
        if (this.formValues.isInfinity) {
          this.formValues.times = 0;
        }
        const typeName = this.medicalList.find(
          (e) => e.medicalId === typeId
        )?.medicalName;
        this.formValues.status = this.isEdit ? 1 : this.formValues.status;
        await saveExam({
          ...this.formValues,
          startTime,
          endTime,
          joinType: 1,
          quList: [],
          openType: 1,
          departIds: [],
          typeName,
        });
        this.$refs.queryTable.runServe({ currentPage: 1 });
      } finally {
        this.dialogShow = false;
      }
    },

    handleSelection(rows) {
      this.selectedRows = rows;
    },

    async getList({ formValues, params }) {
      const { data } = await fetchExamList({
        current: params.currentPage,
        size: params.pageSize,
        params: formValues,
      });
      this.dataList = data?.records || [];
      return {
        data: data?.records,
        total: data?.total,
      };
    },
    async closeExam(ids) {
      ids = ids || this.selectedRows.map((e) => e.id);
      if (ids.length == 0) {
        this.$message.warning("最少选择一个试卷！");
        return;
      }
      const hasExaming = ids.some((id) => {
        const item = this.dataList.find((item) => item.id === id);
        return [1, 3].includes(item.status) || item.state !== 2;
      });
      if (hasExaming) {
        return this.$message.warning("只能关闭考试中的试卷");
      }
      await this.$confirm(`确定关闭考试吗？`);
      await setExamState({
        ids,
        state: 4,
      });
      this.$refs.queryTable.runServe();
    },
  },
};
</script>

<style lang="scss" scoped>
.exam- {
  &operate {
    margin-bottom: 12px;
    text-align: right;
    .el-icon-delete {
      color: #f65160;
    }
  }
}
.tab {
  display: flex;
  justify-content: space-around;
  margin-bottom: 20px;
  height: 36px;
  border-radius: 18px;
  background: #e6edf5;
  line-height: 36px;
  > div {
    flex: 1;
    border-radius: 18px;
    text-align: center;
    cursor: pointer;

    &.current {
      background: var(--colorBlue);
      color: #fff;
    }
  }
}
::v-deep {
  .el-radio--small.is-bordered {
    height: 36px;
  }
}
.statistic- {
  &content {
    padding: 15px;
    border-radius: 14px;
    background: #f4f9fd;
  }
  &name {
    height: 24px;
    color: var(--colorBlue);
    font-weight: 400;
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    line-height: 24px;
  }
  &detail {
    display: flex;
    justify-content: space-around;
    height: 26px;
    color: #7d8592;
    font-weight: 400;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    line-height: 26px;
    .key {
      margin-right: 12px;
    }
  }
  &chart {
    padding: 24px 72px;
    ul {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    .key {
      display: inline-block;
      margin-right: 24px;
      width: 80px;
      &::before {
        display: inline-block;
        margin-right: 12px;
        width: 9px;
        height: 9px;
        border-radius: 50%;
        background: var(--colorRed);
        content: "";
      }
    }
    .value {
      color: var(--colorRed);
    }
  }
}
</style>
