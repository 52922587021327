<template>
  <div style="display: inline-block">
    <div style="display: inline-block" @click="openDialog">
      <slot />
      <el-button v-if="!this.$slots.default" type="primary" :disabled="disabled">请选择</el-button>
    </div>
    <el-dialog
      :title="title"
      :visible.sync="visible"
      :width="width"
      append-to-body
      :lock-scroll="false"
      @close="cancel"
    >
      <selectMore
        v-if="visible"
        ref="selectMore"
        v-model="resultValue"
        :read-only="readOnly"
        :multiple="multiple"
        v-bind="$attrs"
        @listen="listen"
      >
        <div slot="searchForm">
          <slot name="searchForm" />
        </div>
      </selectMore>
      <div slot="footer">
        <el-button class="btn btn_cancle btn_normal" type="default" @click="cancel">
          {{ readOnly ? '关 闭' : '取 消' }}
        </el-button>
        <el-button class="btn btn_blue" v-if="!readOnly" type="primary" @click="submit">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
/* eslint-disable vue/require-prop-types */
import selectMore from './index'
import cloneDeep from 'lodash/cloneDeep'
export default {
  components: {
    selectMore,
  },
  props: {
    multiple: {
      type: Boolean,
      default: false,
    },
    value: {
      required: true,
    },
    title: {
      type: String,
      default: '请选择',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    width: {
      type: String,
      default: '50%',
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      visible: false,
      originValue: [],
      resultValue: [],
    }
  },
  watch: {
    value: {
      immediate: true,
      deep: true,
      handler: function () {
        this.originValue = cloneDeep(this.multiple ? this.value || [] : this.value ? [this.value] : [])
        this.resultValue = cloneDeep(this.multiple ? this.value || [] : this.value ? [this.value] : [])
      },
    },
  },
  // created() {
  //   this.originValue = cloneDeep(this.multiple ? (this.value || []) : (this.value ? [this.value] : []))
  //   this.resultValue = cloneDeep(this.multiple ? (this.value || []) : (this.value ? [this.value] : []))
  // },
  methods: {
    listen(data) {
      this.$emit('listen', data)
    },
    cancel() {
      this.$emit('listen', cloneDeep(this.originValue))
      this.resultValue = cloneDeep(this.originValue)
      this.$emit('cancel')
      this.visible = false
    },
    openDialog() {
      if (this.disabled) return
      this.visible = true
    },
    submit() {
      this.originValue = cloneDeep(this.resultValue)
      const value = this.resultValue
      this.$emit('input', this.multiple ? value : value[0])
      this.$emit('change', this.multiple ? value : value[0])
      this.visible = false
    },
    runServe() {
      this.$refs.selectMore.runServe()
    },
  },
}
</script>
