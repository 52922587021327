<template>
  <YForm v-model="formValues" inline>
    <YField v-if="needOnlySelected" component="el-checkbox" name="onlySelected" label="已选择" />
    <div style="display:inline-block">
      <slot name="searchForm" />
    </div>
    <YQueryTable ref="YQueryTable" max-height="500" :columns="columnsResult" :serve="computedServe" @refreshListCb="setlist" />
  </YForm>
</template>
<script>
/* eslint-disable vue/require-prop-types */
import cloneDeep from 'lodash/cloneDeep'
export default {
  props: {
    value: {
      required: true
    },
    columns: {
      type: Array,
      default: () => ([])
    },
    // eslint-disable-next-line vue/require-default-prop
    serve: {},
    multiple: {
      type: Boolean,
      default: false
    },
    rowKey: {
      type: String,
      required: true
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    needOnlySelected: {
      type: Boolean,
      default: false
    },
    handleList: {
      type: Function,
      default: list => list
    }
  },
  data() {
    return {
      resultValue: [],
      formValues: {},
      checkedAll: false,
      list: []
    }
  },
  computed: {
    computedServe() {
      return this.formValues.onlySelected ? this.onlySelected : this.handleServe()
    },
    // TODO: 联调要看数据能不能反显选中
    listCheckedValue() {
      const { list, resultValue, rowKey } = this
      return list.map(ele => {
        let isChecked = false
        resultValue && resultValue.forEach(v => {
          if (v[rowKey] === ele[rowKey]) {
            isChecked = true
          }
        })
        return isChecked
      })
    },
    indeterminate() {
      let isAllChecked = false
      const l = this.listCheckedValue.filter(e => e === true).length
      if (l > 0 && l < this.listCheckedValue.length) {
        isAllChecked = true
      }
      return isAllChecked
    },
    columnsResult() {
      const checkboxColumn = {
        // eslint-disable-next-line no-unused-vars
        'render-header': (h) => (<el-checkbox disabled={this.readOnly} v-model={this.checkedAll} onChange={this.onCheckedAll} indeterminate={this.indeterminate}></el-checkbox>),
        width: '50px',
        render: ({ row, $index }) => {
          return (
            <el-checkbox value={this.listCheckedValue[$index]} onChange={(v) => this.onCheckedItem(v, row)} disabled={row._disabled || false || this.readOnly}></el-checkbox>
          )
        }
      }
      const radioColumn = {
        label: '选择',
        width: '50px',
        render: ({ row, $index }) => {
          return (
            <div class='radio-container'>
              <el-radio
                class='radio-fix'
                value={this.radioValue}
                label={row[this.rowKey]}
                disabled={row._disabled || false || this.readOnly}
                onChange={() => this.onRadioItem($index, row)}
              >
                <span></span>
              </el-radio>
            </div>
          )
        }
      }
      return [
        this.multiple ? checkboxColumn : radioColumn,
        ...this.columns
      ]
    },
    radioValue() {
      if (this.multiple) return null
      const i = this.listCheckedValue.findIndex(e => e === true)
      return i > -1 ? this.list[i][this.rowKey] : null
    }
  },
  watch: {
    // value: {
    //   deep: true,
    //   handler: function (value, oldValue) {
    //     // if (JSON.stringify(value) !== JSON.stringify(oldValue)) {
    //     //   this.resultValue = cloneDeep(this.multiple ? (value || []) : (value ? [value] : []))
    //     // }
    //   }
    // },
    resultValue(value) {
      this.$emit('input', value)
      this.$emit('change', value)
    }
  },
  created() {
    this.resultValue = cloneDeep(this.multiple ? (this.value || []) : (this.value ? [this.value] : []))
  },
  methods: {
    async onlySelected() {
      this.formValues = {
        onlySelected: this.formValues.onlySelected
      }
      return {
        data: this.handleList(this.value)
      }
    },
    handleServe() {
      this.formValues.onlySelected = false
      return this.serve
    },
    onCheckedAll() {
      const flg = this.checkedAll
      this.list.filter(item => !item._disabled).forEach(item => {
        this.onCheckedItem(flg, item)
      })
    },
    onCheckedItem(isChecked, data) {
      if (isChecked) {
        // 去重
        const isRepeate = this.resultValue.filter(ele => {
          return ele[this.rowKey] === data[this.rowKey]
        }).length > 0
        !isRepeate && this.resultValue.push(data)
      } else {
        this.onCancelCheckItem(data)
      }
      this.$emit('listen', this.resultValue)
      this.calcCheckAll()
    },
    calcCheckAll() {
      // // 重新计算全选值
      const selected = this.list.filter(ele => {
        return this.resultValue.map(e => e[this.rowKey]).includes(ele[this.rowKey])
      })
      this.checkedAll = selected.length === this.list.length
    },
    onCancelCheckItem(data) {
      this.resultValue = this.resultValue.filter(ele => ele[this.rowKey] !== data[this.rowKey])
    },
    onRadioItem(i) {
      this.resultValue = this.list.filter((ele, j) => i === j)
    },
    setChecked(data) {
      const value = this.resultValue.concat(data)
      // 去重
      const results = []
      value.forEach(item => {
        const isRepeat = results.filter(ele => ele[this.rowKey] === item[this.rowKey])
        if (!isRepeat.length) {
          results.push(item)
        }
      })
      this.resultValue = results
    },
    setlist({ list }) {
      this.list = list || []
      this.calcCheckAll() // 翻页重新计算全选状态
    },
    runServe() {
      this.$refs.YQueryTable.runServe()
    }
  }
}
</script>
<style lang="scss">
.radio-container {
	overflow: hidden;
	width: 20px;
	height: 20px;
	// background: red;
	.radio-fix {
		position: absolute;
		left: 12px;
		top: 20px;
	}
}
</style>
